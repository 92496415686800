@layer utilities {
    /**********************************
    ******* TRANSITIONS
    **********************************/

    .transition-default {
        @apply transition-all duration-200 ease-smooth-out;
    }

    .transition-medium {
        @apply transition-all duration-300 ease-smooth-out;
    }

    .transition-medium-delay {
        @apply transition-all duration-300 ease-smooth-out delay-200;
    }

    .transition-slow {
        @apply transition-all duration-700 ease-smooth-out;
    }

    .transition-current-color {
        @apply transition-colors duration-300 ease-smooth-out;
    }

    /**********************************
    ******* ANIMATIONS
    **********************************/

    /* Animation class for title prelaod */
    .title-preamble-preload-animate {
        background-size: 400% 400%;
        animation: TitlePreamblePreload 6s ease infinite;
    }

    /* Keyframes for text loader */
    @keyframes TitlePreamblePreload {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 51%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    /**********************************
    ******* PAGE
    **********************************/
    .page-mx {
        @apply mx-16 sm:mx-24 lg:mx-32;
    }

    .page-ml {
        @apply ml-16 sm:ml-24 lg:ml-32;
    }

    .page-mr {
        @apply mr-16 sm:mr-24 lg:mr-32;
    }

    .page-px {
        @apply px-16 sm:px-24 lg:px-32;
    }

    .page-pl {
        @apply pl-16 sm:pl-24 lg:pl-32;
    }

    .page-pr {
        @apply pr-16 sm:pr-24 lg:pr-32;
    }

    /**********************************
    ******* INPUT ELEMENTS
    **********************************/

    /* Base */
    .input-label {
        @apply t-h5;
    }

    .input-label span {
        @apply ml-8 t-caption;
    }

    .input-sublabel {
        @apply t-small;
    }

    .input-utility-text {
        @apply t-caption;
    }

    .input-utility-text-error {
        @apply text-coral-300;
    }

    .input-defaults,
    .input-defaults-date input {
        @apply text-20 leading-normal font-medium  transition-default focus:outline-none outline-none  focus:ring-2 rounded-4 h-40 px-12 pt-[9px] pb-[5px] disabled:!opacity-70;
    }

    .input-defaults-date input {
        @apply grow w-full;
    }

    .input-defaults-error,
    .input-defaults-date-error input {
        @apply ring-2 !ring-coral-300 !bg-coral-10 !text-coral-300 !placeholder-coral-60;
    }

    .input-defaults::placeholder,
    .input-defaults::-moz-placeholder {
        font-weight: 400;
    }

    .input-defaults-select-options {
        @apply z-above absolute left-0 w-full overflow-auto text-16 leading-normal font-medium shadow-lg max-h-[220px] transition-default focus:outline-none outline-none  focus:ring-2 rounded-4 space-y-2 py-4;
    }
    .input-defaults-select-option {
        @apply px-16 pt-10 pb-6 select-none t-small;
    }
    .input-defaults-select-option-selected {
        @apply font-bold;
    }

    /* Theming - BLUE (on blue background) */
    .input-label-blue,
    .input-sublabel-blue {
        @apply text-blue-100;
    }
    .input-utility-text-blue,
    .input-label-blue span {
        @apply text-blue-80;
    }
    .input-defaults-blue,
    .input-defaults-date-blue input {
        @apply placeholder-blue-60 bg-white text-blue-100 focus:ring-blue-100 disabled:!text-blue-60;
    }
    .input-defaults-select-options-blue {
        @apply bg-white text-blue-100;
    }
    .input-defaults-select-option-active-blue {
        @apply bg-blue-10;
    }

    /* Theming - BLUE (on white background) */
    .input-label-blue-alt,
    .input-sublabel-blue-alt {
        @apply text-blue-100;
    }
    .input-utility-text-blue-alt,
    .input-label-blue-alt span {
        @apply text-blue-80;
    }
    .input-defaults-blue-alt,
    .input-defaults-date-blue-alt input {
        @apply placeholder-blue-60 bg-blue-10 text-blue-100 focus:ring-blue-100 disabled:!text-blue-60;
    }
    .input-defaults-select-options-blue-alt {
        @apply bg-blue-10 text-blue-100;
    }
    .input-defaults-select-option-active-blue-alt {
        @apply bg-blue-10;
    }

    /* Theming - TEAL */
    .input-label-teal,
    .input-sublabel-teal {
        @apply text-teal-100;
    }
    .input-utility-text-teal,
    .input-label-teal span {
        @apply text-teal-80;
    }
    .input-defaults-teal,
    .input-defaults-date-teal input {
        @apply placeholder-teal-60 bg-teal-10 text-teal-100 focus:ring-teal-100 disabled:!text-teal-60;
    }
    .input-defaults-select-options-teal {
        @apply bg-teal-10 text-teal-100;
    }
    .input-defaults-select-option-active-teal {
        @apply bg-teal-20;
    }

    /* Search (landing) */
    .input-search {
        @apply w-full px-24 pt-16 pb-10 text-blue-100 bg-white outline-none t-sh3 placeholder-blue-60 transition-default focus:outline-none focus:ring-blue-100 focus:ring-2 rounded-8;
    }

    /**********************************
    ******* PIE CHART
    https://keithclark.co.uk/articles/single-element-pure-css-pie-charts/
    **********************************/
    .pie {
        @apply relative w-full h-0 pb-[100%] rounded-[50%];

        /* EXMAPLE of calculation of PIE SLICES

        20%             20  * 3.6 = 72 degrees      (end of segment 1)
        45%                         72 degreess      (start of segment 2)
        45%       (45 + 20) * 3.6 = 234 degrees      (end of segment 2)
        15%                         234 degrees      (start of segment 3)
        15%   (45 + 20 + 15) * 3.6 = 288 degrees     (end of segment 3)
        20%     last stop is always 360 degrees      (start of segment 4)

        background-image: conic-gradient(
            #ff0000 72deg,
            #ff00ff 72deg,
            #ff00ff 234deg,
            #fff000 234deg,
            #fff000 288deg,
            #0000ff 0
        );
        */
    }
    .pie:before {
        /* Donut width = 35px */
        content: '';
        position: absolute;
        width: calc(100% - (2 * 35px));
        height: calc(100% - (2 * 35px));
        top: 35px;
        left: 35px;
        border-radius: 50%;
        background-color: #fff;
    }

    /**********************************
    ******* MISC
    **********************************/
    .offset-anchor {
        padding-top: 96px;
        margin-top: -96px;
    }

    .offset-anchor-legacy {
        padding-top: 96px;
        margin-top: -72px;
    }

    /* Tab navigation - Background */
    .tab-nav-bg:before {
        @apply absolute inset-0 bg-amber-10 h-[58px];
        z-index: -1;
        content: '';
    }

    .scrollbar-hide {
        /* IE and Edge */
        -ms-overflow-style: none;

        /* firefox */
        scrollbar-width: none;
    }

    /* safari and chrome */
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /**********************************
    ******* WYSIWYG
    **********************************/

    .rsw-editor-theme-blue-alt {
        .rsw-editor {
            @apply !text-20 !leading-normal !font-medium !transition-default focus-within:ring-blue-100 focus-within:!outline-none !outline-none focus-within:!ring-2 !rounded-4 disabled:!opacity-70 !border-none !p-0;
        }

        .rsw-editor::placeholder,
        .rsw-editor::-moz-placeholder {
            font-weight: 400;
        }

        .rsw-btn {
            @apply flex items-center justify-center w-40 h-40 rounded-4 !text-blue-100;
        }

        .rsw-toolbar {
            @apply !border-b-0 p-8 flex space-x-4;
        }

        .rsw-ce {
            @apply text-20 leading-normal font-medium transition-default focus:!outline-none outline-none focus:ring-2 px-12 pt-[9px] pb-[5px] disabled:!opacity-70;
        }

        .rsw-ce {
            @apply !placeholder-blue-60 !bg-blue-10 !text-blue-100 focus:ring-0 focus:outline-none disabled:!text-blue-60;
        }
    }

    .rsw-editor-theme-teal {
        .rsw-editor {
            @apply !text-20 !leading-normal !font-medium !transition-default focus-within:ring-teal-100 focus-within:!outline-none !outline-none focus-within:!ring-2 !rounded-4 disabled:!opacity-70 !border-none !p-0;
        }

        .rsw-editor::placeholder,
        .rsw-editor::-moz-placeholder {
            font-weight: 400;
        }

        .rsw-btn {
            @apply flex items-center justify-center w-40 h-40 rounded-4 !text-teal-100;
        }

        .rsw-toolbar {
            @apply !border-b-0 p-8 flex space-x-4;
        }

        .rsw-ce {
            @apply text-20 leading-normal font-medium transition-default focus:!outline-none outline-none focus:ring-2 px-12 pt-[9px] pb-[5px] disabled:!opacity-70;
        }

        .rsw-ce {
            @apply !placeholder-teal-60 !bg-teal-10 !text-teal-100 focus:ring-0 focus:outline-none disabled:!text-teal-60;
        }
    }

    .rsw-editor-theme-blue {
        .rsw-editor {
            @apply !text-20 !leading-normal !font-medium !transition-default focus-within:ring-blue-100 focus-within:!outline-none !outline-none focus-within:!ring-2 !rounded-4 disabled:!opacity-70 !border-none !p-0;
        }

        .rsw-editor::placeholder,
        .rsw-editor::-moz-placeholder {
            font-weight: 400;
        }

        .rsw-btn {
            @apply flex items-center justify-center w-40 h-40 rounded-4 !text-blue-100;
        }

        .rsw-toolbar {
            @apply !border-b-0 p-8;
        }

        .rsw-ce {
            @apply text-20 leading-normal font-medium transition-default focus:!outline-none outline-none focus:ring-2 px-12 pt-[9px] pb-[5px] disabled:!opacity-70;
        }

        .rsw-ce {
            @apply !placeholder-blue-60 bg-white !text-blue-100 focus:ring-blue-100 focus:outline-none disabled:!text-blue-60;
        }
    }

    .wysiwyg {
        @apply whitespace-normal;
        ul {
            @apply list-disc !pl-32;
        }
        ol {
            @apply list-decimal !pl-32;
        }
    }
}
