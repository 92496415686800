@layer utilities {
    /* PDF Report Specific */
    .t-pdf-h1 {
        @apply font-bold leading-normal font-heading text-72;
    }

    /* Headings */
    .t-h1 {
        @apply font-bold leading-normal font-heading text-36 xl:text-42 3xl:text-48;
    }
    .t-h2 {
        @apply font-bold leading-normal font-heading text-32 xl:text-36 3xl:text-40;
    }
    .t-h3 {
        @apply font-bold leading-normal font-heading text-28 xl:text-30 3xl:text-32;
    }
    .t-h4 {
        @apply font-bold leading-normal font-heading text-24;
    }
    .t-h5 {
        @apply font-bold leading-normal font-heading text-20;
    }
    .t-h6 {
        @apply font-bold leading-normal font-heading text-18;
    }

    /* Sub headings */
    .t-sh1 {
        @apply font-normal leading-normal font-heading text-32 xl:text-36 3xl:text-40;
    }
    .t-sh2 {
        @apply font-normal leading-normal font-heading text-28 xl:text-30 3xl:text-32;
    }
    .t-sh3 {
        @apply font-normal leading-normal font-heading text-24;
    }
    .t-sh4 {
        @apply font-normal leading-normal font-heading text-20;
    }
    .t-sh5 {
        @apply font-normal leading-normal font-heading text-18;
    }
    .t-sh6 {
        @apply font-normal leading-normal font-heading text-16;
    }
    .t-sh7 {
        @apply font-normal leading-normal font-heading text-14;
    }

    /* Other */
    .t-preamble {
        @apply font-medium leading-normal text-24;
    }
    .t-body {
        @apply font-normal leading-normal font-body text-20;

        /* Sane standards for list items, also the disc and numbers */
        ul,
        ol {
            @apply mt-4 list-outside ml-16;
        }

        ul {
            @apply list-disc;
        }

        ol {
            @apply list-decimal;
        }
    }
    .t-small {
        @apply font-normal leading-normal font-body text-18;
    }
    .t-caption {
        @apply font-medium leading-normal font-body text-16;
    }
    .t-caption-bold {
        @apply font-bold leading-normal font-body text-16;
    }
    .t-footnote {
        @apply leading-normal font-body text-14;
    }
    .t-footnote-bold {
        @apply font-bold leading-normal font-body text-14;
    }
    .t-aside-nav {
        @apply font-medium leading-normal font-heading text-14;
    }
    .t-tiny {
        @apply font-normal leading-normal font-body text-12;
    }
}
